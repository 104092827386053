import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../Tooltip/Tooltip";
import { HeaderCompanyImageContainer , CompanyImageHeader, CompanyTitleHeader } from "./HeaderCompanyImage.styled";
import {ReactComponent as LogoDefault } from "../../../assets/images/svg/company-logo-default.svg";

const HeaderCompanyImage = ({logoImage,title,logoUrl}) => {
    
    return (

        <Tooltip title={title} placement="left">
            <HeaderCompanyImageContainer> 
                {logoUrl && logoUrl !== "logo" ? (
                    <CompanyImageHeader src={logoImage}></CompanyImageHeader>
                ) : (
                    <LogoDefault/>
                )}
                <CompanyTitleHeader className="company-title"> {title} </CompanyTitleHeader> 
            </HeaderCompanyImageContainer>
        </Tooltip>
        
    )
};

HeaderCompanyImage.propTypes = {
    logoImage: PropTypes.string,
    title:PropTypes.string,
    logoUrl:PropTypes.string,
  };
  
export default HeaderCompanyImage;