import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  clearCommonTemplates,
  fetchCommonTemplates,
  fetchLanguages,
} from "../../../../store/actions/commonTemplates/commonTemplatesActions";
import {
  selectCommonTemplatesData,
  selectLanguages,
  selectTotalCommonTemplates,
} from "../../../../store/selectors/commonTemplatesSelector";
import commonTemplatesTableConstants from "../../../../constants/tableConstants/commonTemplatesTableConstants";
import TextRenderer from "../../../Table/Renderers/TextRenderer/TextRenderer";
import Table from "../../../Table/Table";
import DropdownFilterComponent from "../../../Findings/FindingsTable/FilterComponents/DropdownFilterComponent/DropdownFilterComponent";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../../store/actions/categories/categoriesAction";
import { selectCategories } from "../../../../store/selectors/categoriesSelector";
import { useHistory } from "react-router-dom";
import { findCategoryByName } from "../../../../constants/categoriesConstants";


const TemplatesTable = ({onRowClick, selectFirstRow}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const categories1 = useSelector(selectCategories);
  //const templates = useSelector(selectCommonTemplatesData);
  const languages = useSelector(selectLanguages);
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [customPayload, setCustomPaylaod] = useState({});

  useEffect(() => {
    setSelectedCategory(history?.location?.state?.filterPayload?.category);
    setSelectedLanguage(history?.location?.state?.filterPayload?.language);
    setCustomPaylaod({language: history?.location?.state?.filterPayload?.language, category:history?.location?.state?.filterPayload?.category})
    dispatch(fetchCategories());
    dispatch(fetchLanguages());
    
  }, []);
 
  const handleRowClick = (rowData) => {
    onRowClick(rowData)  
  };

  const handleResetFilter = () => {
    setCustomPaylaod({});
    setSelectedCategory();
    setSelectedLanguage();
    history?.replace?.({
      state: {
        ...history?.location?.state,
        filterPayload: {},
      },
    });
  };

  const filterComponents = [
    <DropdownFilterComponent
      key={0}
      data={categories1.data.map(x => ({...x,resourceKey:t(x.resourceKey)}))}
      label={t("commonTemplate.filter.categoryTitle")}
      placeholder={t("commonTemplate.filter.categoryTitle")}
      selectedData={selectedCategory}
      setSelectedData={setSelectedCategory}
      dropdownLabel={["resourceKey"]}
    />,
    <DropdownFilterComponent
      key={1}
      data={languages?.data?.map?.(x =>({id:x,languageCultureName:x }))}
      label={t("commonTemplate.filter.languageTitle")}
      placeholder={t("commonTemplate.filter.languageTitle")}
      selectedData={selectedLanguage}
      setSelectedData={setSelectedLanguage}
      dropdownLabel={["languageCultureName"]}
      useText={true}
    />
    
  ]

  const applyFiltersFunction = () => {
    history?.replace?.({
      state: {
        ...history?.location?.state,
        filterPayload: {
          category: selectedCategory,
          language: selectedLanguage,
          //search: searchTerm,
        },
      },
    });
   // tableRef?.current?.forceRefetch();
    setCustomPaylaod({language: selectedLanguage, category:selectedCategory});
  }

  
  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(commonTemplatesTableConstants).map(
      (property) => {
        return {
          ...commonTemplatesTableConstants[property],
          field: t(commonTemplatesTableConstants[property].i18key),
          propertyName: property,
          backendProperty:
            commonTemplatesTableConstants[property]?.backendProperty || 
            property,
          style: commonTemplatesTableConstants[property]?.style,
          renderer:
            commonTemplatesTableConstants[property]?.renderer || TextRenderer,
          rendererProps: { hideChangePassword: true },
        };
      }
    );
    return columnsObject;
  }, [commonTemplatesTableConstants, t]);

  const mapDataFunction = useCallback((newData) => {
    return newData.map((singleData) => {
      
      let category = (findCategoryByName(singleData?.commonTemplateCategory?.caption))
      let categoryName = category ? t(category.i18key) : singleData?.commonTemplateCategory?.caption;
      let mappedDataObject = {
        caption: singleData.scanningSubjectCaption,
        category: { name: categoryName},
        language: singleData.languageCultureName,
        id: singleData?.id,
      };
      return mappedDataObject;
    });
  }, []);
 
  return (
    <Table
      sortingColumn="ScanningSubjectCaption"
      ref={tableRef}
      tableColumns={tableColumns}
      clickable
      //hideSearch
      initialSize={5}
      showFilter
      onRowClick={handleRowClick}
      hideAddButton
      searchPlaceholder={t("commonTemplate.searchPlaceholder")}
      dataSelector={selectCommonTemplatesData}
      totalDataSelector={selectTotalCommonTemplates}
      customPayload={customPayload}
      filterResetFunc={handleResetFilter}
      applyFiltersFunction={applyFiltersFunction}
      dispatchFunction={fetchCommonTemplates}
      mapDataFunction={mapDataFunction}
      clearDispatchFunction={clearCommonTemplates}
      onClick={() => handleRowClick()}
      isSavedInHistory ={false}
      filterComponents={filterComponents} 
      selectFirstRow= {selectFirstRow}
    />
  );
};

TemplatesTable.propTypes = {
  children: PropTypes.node,
  onRowClick:PropTypes.any,
  selectFirstRow: PropTypes.bool
};

export default TemplatesTable;
