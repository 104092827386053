export default {
  "app.title": " ",
  "apiErrors.error": " ",
  "apiErrors.500": " ",
  "notOnline.title": " ",
  "notOnline.description": " ",
  "common.close": " ",
  "common.okay": " ",
  "common.open": " ",
  "common.trademark": " ",
  "common.noOptions": " ",
  "common.search": " ",
  "common.error": " ",
  "common.continue": " ",
  "common.labelUsername": " ",
  "common.labelPassword": " ",
  "common.next": " ",
  "common.nextPage": " ",
  "common.previousPage": " ",
  "common.back": " ",
  "common.goBack": " ",
  "common.ok": " ",
  "common.done": " ",
  "common.confirm": " ",
  "common.apply": " ",
  "common.giveUp": " ",
  "common.cancel": " ",
  "common.remove": " ",
  "common.invite": " ",
  "common.save": " ",
  "common.submit": " ",
  "common.complete": " ",
  "common.download": " ",
  "common.activate": " ",
  "common.deactivate": " ",
  "common.loading": " ",
  "common.add": " ",
  "common.yes": " ",
  "common.no": " ",
  "common.to": " ",
  "common.link": " ",
  "common.select": " ",
  "common.choose": " ",
  "common.requiredFieldsText": " ",
  "common.date.range": " ",
  "common.created": " ",
  "common.lastChanges": " ",
  "common.name": " ",
  "common.reset": " ",
  "common.active": " ",
  "common.answer": " ",
  "common.promptLeave": " ",
  "common.saveFirst": " ",
  "common.copy": " ",
  "common.email": " ",
  "common.setPassword": " ",
  "common.changePassword": " ",
  "common.changePasswordEmail": " ",
  "common.passwordEntered": " ",
  "common.change": " ",
  "common.delete": " ",
  "common.pdf": " ",
  "login.logInTitle": " ",
  "login.emailLabel": " ",
  "login.emailPlaceholder": " ",
  "login.passwordLabel": " ",
  "login.rememberMeLabel": " ",
  "login.loginButton": " ",
  "login.emailFormat": " ",
  "login.usernameRequired": " ",
  "login.passwordRequired": " ",
  "login.emailPasswordIncorect": " ",
  "changePassword.newPassword": " ",
  "changePassword.newPasswordLabel": " ",
  "changePassword.confirmPassword": " ",
  "changePassword.confirmPasswordLabel": " ",
  "changePassword.blankPasswordError": " ",
  "changePassword.differentPasswordsError": " ",
  "changePassword.changePasswordTitle": " ",
  "changePassword.passwordNumberOfCharacters": " ",
  "changePassword.validationPasswordDescription": " ",
  "changePassword.passwordLength": " ",
  "changePassword.numbersRequred": " ",
  "changePassword.specialCharactersRequired": " ",
  "changePassword.lettersRequred": " ",
  "changePassword.changedPasswordSuccess": " ",
  "changePassword.changedPasswordSuccessDescription": " ",
  "forgotPassword.title": " ",
  "forgotPassword.label": " ",
  "forgotPassword.emailRequired": " ",
  "forgotPassword.emailFormat": " ",
  "forgotPassword.forgotPassword.title": " ",
  "forgotPassword.forgotPassword.subtitle": " ",
  "forgotPassword.forgotPassword.label": " ",
  "table.filter": " ",
  "table.title": " ",
  "table.subtitle": " ",
  "table.addRow": " ",
  "table.removeRow": " ",
  "table.copyRow": " ",
  "table.addRowNotAllowed": " ",
  "table.renameColumns.title": " ",
  "table.renameColumns.rename": " ",
  "table.renameColumns.titleRequired": " ",
  "table.renameColumns.subtitleRequired": " ",
  "table.renameColumns.errors.existingWithSubtitle": " ",
  "table.renameColumns.errors.existingSameSubtitle": " ",
  "table.renameColumns.errors.onlySubtitle": " ",
  "table.dropdown.desc1": " ",
  "table.dropdown.desc2": " ",
  "roles.admin": " ",
  "roles.manager": " ",
  "roles.guest": " ",
  "roles.operator": " ",
  "users.title": " ",
  "users.typeOfData": " ",
  "users.typeOfDataPlural": " ",
  "users.searchPlaceholder": " ",
  "users.table.users.columnTitle": " ",
  "users.table.email.columnTitle": " ",
  "users.table.lastLogin.columnTitle": " ",
  "users.table.status.columnTitle": " ",
  "users.table.status.active": " ",
  "users.table.status.inactive": " ",
  "users.table.role.columnTitle": " ",
  "users.table.lastChanges.columnTitle": " ",
  "users.table.actions.columnTitle": " ",
  "users.table.header.columnTitle": " ",
  "users.newUser": " ",
  "users.basicInfo": " ",
  "users.accountInfo": " ",
  "users.firstName": " ",
  "users.lastName": " ",
  "users.created": " ",
  "users.userRole": " ",
  "users.email": " ",
  "users.password": " ",
  "users.confirmPassword": " ",
  "users.setPassword": " ",
  "users.cancel": " ",
  "users.save": " ",
  "users.editUser": " ",
  "users.deleteUser": " ",
  "users.createUser": " ",
  "users.activateUser": " ",
  "users.deactivateUser": " ",
  "users.userChangedSuccess": " ",
  "users.tabActive": " ",
  "users.tabDeactive": " ",
  "users.newUserPlaceholders.firstName": " ",
  "users.newUserPlaceholders.lastName": " ",
  "users.newUserPlaceholders.email": " ",
  "users.newUserPlaceholders.password": " ",
  "users.newUserPlaceholders.confirmPassword": " ",
  "users.modal.deactivate.title": " ",
  "users.modal.deactivate.description": " ",
  "users.modal.activate.title": " ",
  "users.modal.activate.description": " ",
  "users.tooltip.changePassword": " ",
  "users.tooltip.changeUser": " ",
  "paging.next": " ",
  "paging.prev": " ",
  "companySettings.title": " ",
  "companySettings.companyName": " ",
  "companySettings.PIB": " ",
  "companySettings.identificationNumber": " ",
  "companySettings.address": " ",
  "companySettings.companyDirector": " ",
  "companySettings.email": " ",
  "companySettings.logo": " ",
  "companySettings.choseLogo": " ",
  "companySettings.signature": " ",
  "companySettings.chooseSignature": " ",
  "companySettings.stamp": " ",
  "companySettings.chooseStamp": " ",
  "companySettings.companyLicense": " ",
  "companySettings.licenses": " ",
  "companySettings.choseCompanyLicense": " ",
  "companySettings.APR": " ",
  "companySettings.choseAPR": " ",
  "companySettings.save": " ",
  "companySettings.cancel": " ",
  "companySettings.createCompanyFirst": " ",
  "companySettings.companyNameRequired": " ",
  "companySettings.PIBRequred": " ",
  "companySettings.PIBnoOfCharacters": " ",
  "companySettings.idNumberRequred": " ",
  "companySettings.idNumberNoOfCharacters": " ",
  "companySettings.addressRequired": " ",
  "companySettings.emailRequired": " ",
  "companySettings.moreImagesAllowed": " ",
  "companySettings.choseAPRSmall": " ",
  "companySettings.distributionEmails": " ",
  "companySettings.newEmail": " ",
  "companySettings.users": " ",
  "companySettings.bankAccountNumber": " ",
  "companySettings.phoneNumber": " ",
  "companySettings.bankAccountNumberRequired": " ",
  "companySettings.phoneNumberRequired": " ",
  "companySettings.companyChangedSuccess": " ",
  "companySettings.companyCreatedSuccess": " ",
  "companySettings.general": " ",
  "companySettings.mail": " ",
  "companySettings.autoSendMails": " ",
  "companySettings.archive.documentType.label": " ",
  "companySettings.archive.documentType.placeholder": " ",
  "companySettings.archive.autoSave": " ",
  "companySettings.archive.dropdown": " ",
  "companySettings.archive.googleDrive.driveId": " ",
  "companySettings.archive.googleDrive.driveSecret": " ",
  "companySettings.archive.googleDrive.descriptionText": " ",
  "companySettings.archive.googleDrive.addedFileDesc": " ",
  "companySettings.archive.googleDrive.verify": " ",
  "companySettings.archive.googleDrive.verifyText": " ",
  "companySettings.archive.googleDrive.driveVerified": " ",
  "companySettings.archive.googleDrive.fileUploaded": " ",
  "companySettings.archive.googleDrive.driveIdRequried": " ",
  "companySettings.archive.googleDrive.fileRequired": " ",
  "companySettings.confirmModal.title": " ",
  "companySettings.confirmModal.description": " ",
  "companySettings.confirmModal.cancel": " ",
  "companySettings.confirmModal.save": " ",
  "emailSettings.userNameRequired": " ",
  "emailSettings.hostRequired": " ",
  "emailSettings.sslRequired": " ",
  "emailSettings.portRequired": " ",
  "emailSettings.portIsNumber": " ",
  "emailSettings.passwordRequired": " ",
  "emailSettings.emailFormat": " ",
  "emailSettings.label.userName": " ",
  "emailSettings.label.port": " ",
  "emailSettings.label.host": " ",
  "emailSettings.label.password": " ",
  "emailSettings.label.ssl": " ",
  "emailSettings.isVerified": " ",
  "emailSettings.notVerified": " ",
  "emailSettings.verificationHint": " ",
  "emailSettings.buttonLabel": " ",
  "userPopover.logout": " ",
  "userPopover.profile": " ",
  "pages.logout": " ",
  "pages.not-found": " ",
  "pages.settings": " ",
  "pages.newUser": " ",
  "pages.users": " ",
  "pages.editUser": " ",
  "pages.standards": " ",
  "pages.research": " ",
  "pages.clients": " ",
  "pages.profile": " ",
  "pages.newClient": " ",
  "pages.editClient": " ",
  "pages.finding-types": " ",
  "pages.document-types": " ",
  "pages.measuring-instruments": " ",
  "pages.scanning-objects": " ",
  "pages.newScanningObject": " ",
  "pages.editScanningObject": " ",
  "pages.staff": " ",
  "pages.newStuff": " ",
  "pages.editStuff": " ",
  "pages.findings": " ",
  "pages.newFinding": " ",
  "pages.editFinding": " ",
  "pages.personal": " ",
  "pages.home": " ",
  "pages.newDocument": " ",
  "pages.editDocument": " ",
  "pages.documents": " ",
  "pages.companies": " ",
  "pages.newCompany": " ",
  "pages.editCompany": " ",
  "dashboard.title": " ",
  "dashboard.tableSubtitle1": " ",
  "dashboard.tableSubtitle2": " ",
  "dashboard.tableSubtitle3": " ",
  "dashboard.tableSubtitle4": " ",
  "dashboard.table.name.columnTitle": " ",
  "registerUser.firstNameRequired": " ",
  "registerUser.lastNameRequired": " ",
  "registerUser.userRoleRequired": " ",
  "registerUser.emailRequired": " ",
  "registerUser.blankPasswordError": " ",
  "registerUser.confirmPasswordRequred": " ",
  "registerUser.emailExisting": " ",
  "registerUser.firstNameNumberOfCharacters": " ",
  "registerUser.lastNameNumberOfCharacters": " ",
  "registerUser.emailNumberOfCharacters": " ",
  "registerUser.passwordNumberOfCharacters": " ",
  "standards.title": " ",
  "standards.typeOfData": " ",
  "standards.typeOfDataPlural": " ",
  "standards.table.caption.columnTitle": " ",
  "standards.table.isDefault.columnTitle": " ",
  "standards.table.lastChanges.columnTitle": " ",
  "standards.table.findingTypes.columnTitle": " ",
  "standards.table.actions.columnTitle": " ",
  "standards.table.searchPlaceholder": " ",
  "standards.table.newStandard": " ",
  "standards.addStandard": " ",
  "standards.chooseStandard": " ",
  "standards.modal.txtLabel": " ",
  "standards.modal.findingTypesRequired": " ",
  "standards.modal.txtareaPlaceholder": " ",
  "standards.modal.newStandard": " ",
  "standards.modal.editStandard": " ",
  "standards.modal.isDefault": " ",
  "standards.modal.errorMessage": " ",
  "standards.modal.validationMessage": " ",
  "standards.modal.deleteBtnLabel": " ",
  "standards.modal.defaultTitle": " ",
  "standards.modal.chooseTypes": " ",
  "standards.modal.findingTypes": " ",
  "standards.modal.delete.title": " ",
  "standards.modal.delete.description.1": " ",
  "standards.modal.delete.description.2": " ",
  "standards.tooltip.changeStandard": " ",
  "standards.tooltip.deleteStandard": " ",
  "clients.title": " ",
  "clients.typeOfData": " ",
  "clients.typeOfDataPlural": " ",
  "clients.table.clients.columnTitle": " ",
  "clients.table.pib.columnTitle": " ",
  "clients.table.address.columnTitle": " ",
  "clients.table.phoneNumber.columnTitle": " ",
  "clients.table.idNumber.columnTitle": " ",
  "clients.table.created.columnTitle": " ",
  "clients.table.lastChanges.columnTitle": " ",
  "clients.table.actions.columnTitle": " ",
  "clients.table.searchPlaceholder": " ",
  "clients.table.newClient": " ",
  "clients.clientName": " ",
  "clients.pib": " ",
  "clients.address": " ",
  "clients.phoneNumber": " ",
  "clients.idNumber": " ",
  "clients.contactEmail": " ",
  "clients.newEmail": " ",
  "clients.distributionMailList": " ",
  "clients.cancel": " ",
  "clients.newClient": " ",
  "clients.editClient": " ",
  "clients.editClientTitle": " ",
  "clients.deleteClient": " ",
  "clients.createClient": " ",
  "clients.clientNameRequired": " ",
  "clients.clientNameNumberOfCharacters": " ",
  "clients.pibRequired": " ",
  "clients.pibNumberOfCharacters": " ",
  "clients.addressRequired": " ",
  "clients.addressNumberOfCharacters": " ",
  "clients.phoneNumberRequired": " ",
  "clients.phoneNumberNumberOfCharacters": " ",
  "clients.idNumberRequired": " ",
  "clients.idNumberNoOfCharacters": " ",
  "clients.emailRequired": " ",
  "clients.emailFormat": " ",
  "clients.contactEmailNumberOfCharacters": " ",
  "clients.distributionEmailNumberOfCharacters": " ",
  "clients.distributionEmailsEmpty": " ",
  "clients.newClientPlaceholders.clientName": " ",
  "clients.newClientPlaceholders.pib": " ",
  "clients.newClientPlaceholders.address": " ",
  "clients.newClientPlaceholders.phoneNumber": " ",
  "clients.newClientPlaceholders.idNumber": " ",
  "clients.newClientPlaceholders.contactEmail": " ",
  "clients.newClientPlaceholders.distributionEmail": " ",
  "clients.modal.distributionEmails.title": " ",
  "clients.modal.delete.title": " ",
  "clients.modal.delete.description.1": " ",
  "clients.modal.delete.description.2": " ",
  "clients.tooltip.removeDistributionMail": " ",
  "clients.tooltip.mail": " ",
  "clipboard.copy": " ",
  "measuringInstruments.typeOfData": " ",
  "measuringInstruments.typeOfDataPlural": " ",
  "measuringInstruments.title": " ",
  "measuringInstruments.deleteInstrument": " ",
  "measuringInstruments.table.name.columnTitle": " ",
  "measuringInstruments.table.factoryNumber.columnTitle": " ",
  "measuringInstruments.table.manufacturer.columnTitle": " ",
  "measuringInstruments.table.benchmarking.columnTitle": " ",
  "measuringInstruments.table.benchmarking.columnTitleShorter": " ",
  "measuringInstruments.table.benchmarkDate.columnTitle": " ",
  "measuringInstruments.table.lastChanges.columnTitle": " ",
  "measuringInstruments.table.findingTypes.columnTitle": " ",
  "measuringInstruments.table.actions.columnTitle": " ",
  "measuringInstruments.table.delete.columnTitle": " ",
  "measuringInstruments.table.searchPlaceholder": " ",
  "measuringInstruments.table.newInstrument": " ",
  "measuringInstruments.errors.findingTypesRequired": " ",
  "measuringInstruments.errors.nameRequired": " ",
  "measuringInstruments.errors.factoryNumberRequired": " ",
  "measuringInstruments.errors.factoryNumberExisting": " ",
  "measuringInstruments.errors.manufacturerRequired": " ",
  "measuringInstruments.errors.benchmarkingRequired": " ",
  "measuringInstruments.modal.newInstrument": " ",
  "measuringInstruments.modal.editInstrument": " ",
  "measuringInstruments.modal.delete.title": " ",
  "measuringInstruments.modal.delete.description.1": " ",
  "measuringInstruments.modal.delete.description.2": " ",
  "measuringInstruments.modal.chooseCalibrationCertificate": " ",
  "measuringInstruments.modal.fields.name.label": " ",
  "measuringInstruments.modal.fields.name.placeholder": " ",
  "measuringInstruments.modal.fields.factoryNumber.label": " ",
  "measuringInstruments.modal.fields.factoryNumber.placeholder": " ",
  "measuringInstruments.modal.fields.manufacturer.label": " ",
  "measuringInstruments.modal.fields.manufacturer.placeholder": " ",
  "measuringInstruments.modal.fields.benchmarking.label": " ",
  "measuringInstruments.modal.fields.benchmarking.placeholder": " ",
  "measuringInstruments.modal.fields.date.label": " ",
  "measuringInstruments.modal.fields.date.placeholder": " ",
  "measuringInstruments.modal.fields.calibrationCertificate.label": " ",
  "measuringInstruments.tooltip.changeMeasuringInstrument": " ",
  "measuringInstruments.nameRequired": " ",
  "measuringInstruments.factoryNumberRequired": " ",
  "measuringInstruments.manufacturerRequired": " ",
  "measuringInstruments.benchmarkRequired": " ",
  "scanningObjects.typeOfData": " ",
  "scanningObjects.typeOfDataPlural": " ",
  "scanningObjects.title": " ",
  "scanningObjects.positiveConclusion": " ",
  "scanningObjects.negativeConclusion": " ",
  "scanningObjects.table.name.columnTitle": " ",
  "scanningObjects.table.pattern.columnTitle": " ",
  "scanningObjects.table.lastChanges.columnTitle": " ",
  "scanningObjects.table.actions.columnTitle": " ",
  "scanningObjects.table.searchPlaceholder": " ",
  "scanningObjects.table.newScanningObject": " ",
  "scanningObjects.editScanningObject.title": " ",
  "scanningObjects.editScanningObject.submitButton": " ",
  "scanningObjects.duplicateError": " ",
  "scanningObjects.addScanningObject": " ",
  "scanningObjects.addScanningObjectShorter": " ",
  "scanningObjects.chooseScanningObject": " ",
  "scanningObjects.type": " ",
  "scanningObjects.newScanningObject.submitButton": " ",
  "scanningObjects.newScanningObject.title": " ",
  "scanningObjects.newScanningObject.namePlaceholder": " ",
  "scanningObjects.newScanningObject.addTemplate": " ",
  "scanningObjects.newScanningObject.templateEditor.pages.active": " ",
  "scanningObjects.newScanningObject.templateEditor.pages.all": " ",
  "scanningObjects.newScanningObject.templateEditor.addInstrument": " ",
  "scanningObjects.newScanningObject.templateEditor.pickInstrument": " ",
  "scanningObjects.newScanningObject.templateEditor.addStandard": " ",
  "scanningObjects.newScanningObject.templateEditor.pickStandard": " ",
  "scanningObjects.newScanningObject.templateEditor.noInstrumentsText": " ",
  "scanningObjects.newScanningObject.templateEditor.noStandardsText": " ",
  "scanningObjects.newScanningObject.templateEditor.sections": " ",
  "scanningObjects.newScanningObject.templateEditor.addSections": " ",
  "scanningObjects.newScanningObject.templateEditor.createdBy": " ",
  "scanningObjects.newScanningObject.templateEditor.updatedAt": " ",
  "scanningObjects.newScanningObject.templateEditor.archived": " ",
  "scanningObjects.newScanningObject.templateEditor.active": " ",
  "scanningObjects.newScanningObject.templateEditor.newSection": " ",
  "scanningObjects.addFirstItem": " ",
  "scanningObjects.deleteScanningObject": " ",
  "scanningObjects.copyScanningObject": " ",
  "scanningObjects.noTemplate": " ",
  "scanningObjects.noTemplatev2": " ",
  "scanningObjects.noTemplateLink": " ",
  "scanningObjects.nameRequired": " ",
  "scanningObjects.modal.delete.title": " ",
  "scanningObjects.modal.delete.description.1": " ",
  "scanningObjects.modal.delete.description.2": " ",
  "scanningObjects.tooltip.changeScanningObject": " ",
  "scanningObjects.tooltip.removeInstrument": " ",
  "scanningObjects.tooltip.addInstrument": " ",
  "scanningObjects.tooltip.addStandard": " ",
  "scanningObjects.tooltip.editItem": " ",
  "scanningObjects.tooltip.removeItem": " ",
  "scanningObjects.tooltip.editSection": " ",
  "scanningObjects.tooltip.addSection": " ",
  "scanningObjects.tooltip.removeSection": " ",
  "toast.success.createdTitle": " ",
  "toast.success.editedTitle": " ",
  "toast.success.deletedTitle": " ",
  "toast.success.createdDescription": " ",
  "toast.success.editedDescription": " ",
  "toast.success.deletedDescription": " ",
  "toast.success.emailVerificationTitle": " ",
  "toast.success.emailVerificationDescription": " ",
  "toast.success.deactivateTitle": " ",
  "toast.success.deactivateDescription": " ",
  "toast.success.activateTitle": " ",
  "toast.success.activateDescription": " ",
  "toast.success.copyTitle": " ",
  "toast.success.copyDescription": " ",
  "questions.add": " ",
  "questions.new": " ",
  "questions.edit": " ",
  "questions.name": " ",
  "questions.description": " ",
  "questions.namePlaceholder": " ",
  "questions.nameRequired": " ",
  "questions.type": " ",
  "questions.typeAltText": " ",
  "questions.defaultAnswerOptional": " ",
  "questions.defaultAnswerPlaceholder": " ",
  "questions.defaultAnswer": " ",
  "questions.chooseDefaultAnswer": " ",
  "questions.noDefaultAnswer": " ",
  "questions.noteOptional": " ",
  "questions.noteOptionalPlaceholder": " ",
  "questions.possibleAnswers": " ",
  "questions.countedAnswer": " ",
  "questions.requiredAnswer": " ",
  "questions.researchPassed": " ",
  "questions.researchPassedSatisfaction": " ",
  "questions.ordinalNumber": " ",
  "questions.addColumn": " ",
  "questions.columnTitle": " ",
  "questions.columnSubtitle": " ",
  "questions.previewTable": " ",
  "questions.hintText": " ",
  "questions.tableError": " ",
  "tablePreview.title": " ",
  "findingPreview.title": " ",
  "stuffs.title": " ",
  "stuffs.typeOfData": " ",
  "stuffs.typeOfDataPlural": " ",
  "stuffs.table.stuffs.columnTitle": " ",
  "stuffs.table.attribute.columnTitle": " ",
  "stuffs.table.idNumber.columnTitle": " ",
  "stuffs.table.email.columnTitle": " ",
  "stuffs.table.rank.columnTitle": " ",
  "stuffs.table.lastChanges.columnTitle": " ",
  "stuffs.table.actions.columnTitle": " ",
  "stuffs.table.searchPlaceholder": " ",
  "stuffs.table.newStuff": " ",
  "stuffs.firstName": " ",
  "stuffs.lastName": " ",
  "stuffs.email": " ",
  "stuffs.rank": " ",
  "stuffs.license": " ",
  "stuffs.licenseNumber": " ",
  "stuffs.signature": " ",
  "stuffs.chooseSignature": " ",
  "stuffs.newStuff": " ",
  "stuffs.cancel": " ",
  "stuffs.createStuff": " ",
  "stuffs.connectWithUser": " ",
  "stuffs.editStuff": " ",
  "stuffs.editStuffTitle": " ",
  "stuffs.deleteStuff": " ",
  "stuffs.firstNameNumberOfCharacters": " ",
  "stuffs.lastNameNumberOfCharacters": " ",
  "stuffs.rankNumberOfCharacters": " ",
  "stuffs.emailNumberOfCharacters": " ",
  "stuffs.licenseNumberNumberOfCharacters": " ",
  "stuffs.professionRequired": " ",
  "stuffs.emailRequired": " ",
  "stuffs.userRequired": " ",
  "stuffs.licenseRequired": " ",
  "stuffs.dropdownDefault": " ",
  "stuffs.newStuffPlaceholders.firstName": " ",
  "stuffs.newStuffPlaceholders.lastName": " ",
  "stuffs.newStuffPlaceholders.email": " ",
  "stuffs.newStuffPlaceholders.rank": " ",
  "stuffs.newStuffPlaceholders.licenseNumber": " ",
  "stuffs.tooltip.noLicense": " ",
  "stuffs.tooltip.licencePreview": " ",
  "stuffs.modal.previewLicense.title": " ",
  "stuffs.modal.delete.title": " ",
  "stuffs.modal.delete.description.1": " ",
  "stuffs.modal.delete.description.2": " ",
  "sidebarNavigation.home": " ",
  "sidebarNavigation.scanning": " ",
  "sidebarNavigation.standards": " ",
  "sidebarNavigation.scanningInstruments": " ",
  "sidebarNavigation.scanningObjects": " ",
  "sidebarNavigation.scanningTemplate": " ",
  "sidebarNavigation.scanningTemplatePlural": " ",
  "sidebarNavigation.documentTypes": " ",
  "sidebarNavigation.findings": " ",
  "sidebarNavigation.findingTypes": " ",
  "sidebarNavigation.findingsPlural": " ",
  "sidebarNavigation.findingsPreview": " ",
  "sidebarNavigation.findingsPreviewLine": " ",
  "sidebarNavigation.newFinding": " ",
  "sidebarNavigation.clients": " ",
  "sidebarNavigation.users": " ",
  "sidebarNavigation.staff": " ",
  "sidebarNavigation.privilege": " ",
  "sidebarNavigation.personal": " ",
  "sidebarNavigation.documents": " ",
  "sidebarNavigation.companies": " ",
  "companies.title": " ",
  "companies.typeOfData": " ",
  "companies.typeOfDataPlural": " ",
  "companies.newCompany": " ",
  "companies.editCompany": " ",
  "companies.fields.email.label": " ",
  "companies.fields.email.placeholder": " ",
  "companies.tabs.active": " ",
  "companies.tabs.inactive": " ",
  "companies.toast.activated.title": " ",
  "companies.toast.activated.description": " ",
  "companies.toast.deactivated.title": " ",
  "companies.toast.deactivated.description": " ",
  "companies.table.searchPlaceholder": " ",
  "companies.table.name.columnTitle": " ",
  "companies.table.pib.columnTitle": " ",
  "companies.table.idNumber.columnTitle": " ",
  "companies.table.address.columnTitle": " ",
  "companies.table.email.columnTitle": " ",
  "companies.table.lastChanges.columnTitle": " ",
  "companies.table.deactivated.columnTitle": " ",
  "companies.table.header.columnTitle": " ",
  "companies.tooltip.edit": " ",
  "companies.tooltip.delete": " ",
  "companies.tooltip.activate": " ",
  "companies.tooltip.deactivate": " ",
  "instructions.title": " ",
  "findings.title": " ",
  "findings.findingTypes": " ",
  "findings.typeOfData": " ",
  "findings.typeOfDataPlural": " ",
  "findings.table.header.columnTitle": " ",
  "findings.table.createdAtUtc.columnTitle": " ",
  "findings.table.client.columnTitle": " ",
  "findings.table.location.columnTitle": " ",
  "findings.table.staff.columnTitle": " ",
  "findings.table.lastChanges.columnTitle": " ",
  "findings.table.status.columnTitle": " ",
  "findings.table.findingType.columnTitle": " ",
  "findings.table.actions.columnTitle": " ",
  "findings.table.number.columnTitle": " ",
  "findings.table.created.columnTitle": " ",
  "findings.table.searchPlaceholder": " ",
  "findings.table.newStuff": " ",
  "findings.status.inProgress": " ",
  "findings.status.completed": " ",
  "findings.status.closed": " ",
  "findings.status.cancelled": " ",
  "findings.positiveConclusion": " ",
  "findings.negativeConclusion": " ",
  "findings.notFilledMandatoryField": " ",
  "findings.settings": " ",
  "findings.schema": " ",
  "findings.createFindingTitle": " ",
  "findings.editFindingTitle": " ",
  "findings.form.client.title": " ",
  "findings.form.client.placeholder": " ",
  "findings.form.client.required": " ",
  "findings.form.staff.title": " ",
  "findings.form.staff.placeholder": " ",
  "findings.form.staff.required": " ",
  "findings.form.location.title": " ",
  "findings.form.location.placeholder": " ",
  "findings.form.location.required": " ",
  "findings.form.facility.title": " ",
  "findings.form.facility.placeholder": " ",
  "findings.form.facility.required": " ",
  "findings.form.description.title": " ",
  "findings.form.description.placeholder": " ",
  "findings.form.description.required": " ",
  "findings.form.dateOfChecking.title": " ",
  "findings.form.dateOfChecking.placeholder": " ",
  "findings.form.dateOfChecking.required": " ",
  "findings.form.dateOfFinding.title": " ",
  "findings.form.dateOfFinding.placeholder": " ",
  "findings.form.dateOfFinding.required": " ",
  "findings.form.note.title": " ",
  "findings.form.note.placeholder": " ",
  "findings.staffs.title": " ",
  "findings.staffs.addStaff": " ",
  "findings.staffs.chooseStaff": " ",
  "findings.staffs.attribute": " ",
  "findings.staffs.newStaff": " ",
  "findings.staffs.changeStaff": " ",
  "findings.scanning.researchPassed": " ",
  "findings.scanning.selectPlaceholder": " ",
  "findings.tooltip.print": " ",
  "findings.tooltip.edit": " ",
  "findings.modalButtons.measuringInst": " ",
  "findings.modalButtons.standards": " ",
  "findings.findingAttendees.tableTitle": " ",
  "findings.findingAttendees.addButton": " ",
  "findings.findingAttendees.newPerson": " ",
  "findings.findingAttendees.editPerson": " ",
  "findings.findingAttendees.deletePerson": " ",
  "findings.findingAttendees.deleteDescriptionOne": " ",
  "findings.findingAttendees.deleteDescriptionTwo": " ",
  "findings.findingAttendees.nameField": " ",
  "findings.findingAttendees.titleField": " ",
  "findings.findingAttendees.nameColumnTitle": " ",
  "findings.findingAttendees.titleColumnTitle": " ",
  "findings.stepsRange": " ",
  "findings.copy": " ",
  "findings.export": " ",
  "findings.undoCancelled": " ",
  "findings.forwardToMail": " ",
  "findings.cancelFindingModal.title": " ",
  "findings.cancelFindingModal.description": " ",
  "findings.cancelFindingModal.cancelFinding": " ",
  "findings.cancelFindingModal.giveUp": " ",
  "findings.lastChanges": " ",
  "findings.lastStateChanges": " ",
  "findings.sendMailsModal.title": " ",
  "findings.sendMailsModal.inputLabel": " ",
  "findings.sendMailsModal.newMail": " ",
  "findings.sendMailsModal.mailPlaceholder": " ",
  "findings.sendMailsModal.emptyMails": " ",
  "findings.renameScanningSubjectModal.placeholder": " ",
  "findings.renameScanningSubjectModal.label": " ",
  "findings.renameScanningSubjectModal.specificName": " ",
  "findings.sectionNoteTitle": " ",
  "findings.sectionNotePlaceholder": " ",
  "findings.subjectNoteTitle": " ",
  "findings.subjectNotePlaceholder": " ",
  "findings.closeFinding": " ",
  "findings.closeFindingAndSend": " ",
  "findings.closeFindingFirstDesc": " ",
  "findings.closeFindingSecondDesc": " ",
  "findings.filter.reset": " ",
  "findings.filter.findingId": " ",
  "findings.filter.dateMin": " ",
  "findings.filter.dateMax": " ",
  "findings.filter.client": " ",
  "findings.filter.stuff": " ",
  "findings.filter.state": " ",
  "findings.filter.scanningSubject": " ",
  "findings.filter.findingType": " ",
  "findings.filter.placeholder.default": " ",
  "findings.filter.placeholder.scanningSubject": " ",
  "findings.filter.placeholder.input": " ",
  "findings.filter.placeholder.client": " ",
  "findings.filter.placeholder.stuff": " ",
  "findings.filter.placeholder.findingType": " ",
  "findings.filter.placeholder.date": " ",
  "findings.filter.stateArray.all": " ",
  "findings.filter.stateArray.inPreperation": " ",
  "findings.filter.stateArray.completed": " ",
  "findings.filter.stateArray.closed": " ",
  "findings.filter.stateArray.cancelled": " ",
  "findings.imagePicker.addSchema": " ",
  "findings.imagePicker.modalTitle": " ",
  "findings.copyFindingModal.title": " ",
  "findings.copyFindingModal.dateRequired": " ",
  "imagePicker.choose": " ",
  "imagePicker.moreImages": " ",
  "imagePicker.tooltip.changeImage": " ",
  "imagePicker.tooltip.deleteImage": " ",
  "imagePicker.tooltip.showImage": " ",
  "findingTypes.title": " ",
  "findingTypes.typeOfData": " ",
  "findingTypes.typeOfDataPlural": " ",
  "findingTypes.findingType": " ",
  "findingTypes.table.searchPlaceholder": " ",
  "findingTypes.table.newFindingType": " ",
  "findingTypes.table.name.columnTitle": " ",
  "findingTypes.table.caption.columnTitle": " ",
  "findingTypes.table.subcaption.columnTitle": " ",
  "findingTypes.table.lastChanges.columnTitle": " ",
  "findingTypes.table.header.columnTitle": " ",
  "findingTypes.modal.name": " ",
  "findingTypes.modal.caption": " ",
  "findingTypes.modal.subcaption": " ",
  "findingTypes.modal.newFindingType": " ",
  "findingTypes.modal.nameNoOfChars": " ",
  "findingTypes.modal.captionNoOfChars": " ",
  "findingTypes.modal.subcaptionNoOfChars": " ",
  "findingTypes.modal.nameEmpty": " ",
  "findingTypes.modal.captionEmpty": " ",
  "findingTypes.modal.subcaptionEmpty": " ",
  "findingTypes.modal.editFindingType": " ",
  "findingTypes.modal.errorMessage": " ",
  "findingTypes.modal.validationMessage": " ",
  "findingTypes.modal.deleteBtnLabel": " ",
  "findingTypes.modal.delete.title": " ",
  "findingTypes.modal.delete.description.1": " ",
  "findingTypes.modal.delete.description.2": " ",
  "findingTypes.tooltip.changeFindingType": " ",
  "findingTypes.tooltip.deleteFindingType": " ",
  "documents.title": " ",
  "documents.typeOfData": " ",
  "documents.typeOfDataPlural": " ",
  "documents.filter.date.label": " ",
  "documents.filter.date.placeholder": " ",
  "documents.filter.client.label": " ",
  "documents.filter.client.placeholder": " ",
  "documents.filter.documentType.label": " ",
  "documents.filter.documentType.placeholder": " ",
  "documents.table.searchPlaceholder": " ",
  "documents.table.newDocument": " ",
  "documents.table.newDocument.client.columnTitle": " ",
  "documents.table.newDocument.documentNumber.columnTitle": " ",
  "documents.table.newDocument.documentDate.columnTitle": " ",
  "documents.table.newDocument.lastChanges.columnTitle": " ",
  "documents.table.newDocument.link.columnTitle": " ",
  "documents.table.newDocument.documentType.columnTitle": " ",
  "documents.table.newDocument.header.columnTitle": " ",
  "documents.modal.delete.title": " ",
  "documents.modal.delete.description.1": " ",
  "documents.modal.delete.description.2": " ",
  "documents.newPage.newDocument": " ",
  "documents.newPage.editDocument": " ",
  "documents.newPage.document": " ",
  "documents.newPage.documentNumber": " ",
  "documents.newPage.link": " ",
  "documents.newPage.client": " ",
  "documents.newPage.documentDate": " ",
  "documents.newPage.documentType": " ",
  "documents.newPage.documentNumberRequired": " ",
  "documents.newPage.documentDateRequired": " ",
  "documents.newPage.documentTypeRequired": " ",
  "documents.newPage.clientRequired": " ",
  "documents.newPage.linkRequired": " ",
  "documents.newPage.documentNumberNoOfChars": " ",
  "documents.newPage.linkNoOfChars": " ",
  "documents.newPage.linkCheck": " ",
  "documents.newPage.form.client.title": " ",
  "documents.newPage.form.client.placeholder": " ",
  "documents.newPage.form.client.required": " ",
  "documents.newPage.form.documentType.title": " ",
  "documents.newPage.form.documentType.placeholder": " ",
  "documents.newPage.form.documentType.required": " ",
  "documents.newPage.form.documentNumber.title": " ",
  "documents.newPage.form.documentNumber.placeholder": " ",
  "documents.newPage.form.documentNumber.required": " ",
  "documents.newPage.form.link.title": " ",
  "documents.newPage.form.link.placeholder": " ",
  "documents.newPage.form.link.required": " ",
  "documents.newPage.form.link.urlInvalid": " ",
  "documents.newPage.form.documentDate.title": " ",
  "documents.newPage.form.documentDate.placeholder": " ",
  "documents.newPage.form.documentDate.required": " ",
  "documents.newPage.form.finding.title": " ",
  "documents.newPage.form.finding.placeholder": " ",
  "documents.newPage.form.finding.required": " ",
  "documents.tooltip.changeDocument": " ",
  "documents.tooltip.deleteDocument": " ",
  "documentTypes.title": " ",
  "documentTypes.typeOfData": " ",
  "documentTypes.typeOfDataPlural": " ",
  "documentTypes.documentType": " ",
  "documentTypes.table.searchPlaceholder": " ",
  "documentTypes.table.newDocumentType": " ",
  "documentTypes.table.caption.columnTitle": " ",
  "documentTypes.table.description.columnTitle": " ",
  "documentTypes.table.lastChanges.columnTitle": " ",
  "documentTypes.table.header.columnTitle": " ",
  "documentTypes.modal.newDocumentType": " ",
  "documentTypes.modal.editDocumentType": " ",
  "documentTypes.modal.caption": " ",
  "documentTypes.modal.description": " ",
  "documentTypes.modal.captionRequired": " ",
  "documentTypes.modal.descriptionRequired": " ",
  "documentTypes.modal.captionNoOfChars": " ",
  "documentTypes.modal.descriptionNoOfChars": " ",
  "documentTypes.modal.delete.title": " ",
  "documentTypes.modal.delete.description.1": " ",
  "documentTypes.modal.delete.description.2": " ",
  "documentTypes.tooltip.changeDocumentType": " ",
  "documentTypes.tooltip.deleteDocumentType": " ",
  "licenses.title": " ",
  "licenses.typeOfData": " ",
  "licenses.typeOfDataPlural": " ",
  "licenses.license": " ",
  "licenses.table.searchPlaceholder": " ",
  "licenses.table.newLicense": " ",
  "licenses.table.id.columnTitle": " ",
  "licenses.table.startDate.columnTitle": " ",
  "licenses.table.expirationDate.columnTitle": " ",
  "licenses.table.status.columnTitle": " ",
  "licenses.table.lastChanges.columnTitle": " ",
  "licenses.status.active.title": " ",
  "licenses.status.inactive.title": " ",
  "licenses.modal.newLicense": " ",
  "licenses.modal.fields.startDate.label": " ",
  "licenses.modal.fields.startDate.placeholder": " ",
  "licenses.modal.fields.startDate.required": " ",
  "licenses.modal.fields.expirationDate.label": " ",
  "licenses.modal.fields.expirationDate.placeholder": " ",
  "licenses.modal.fields.expirationDate.timecheck": " ",
  "licenses.modal.validationMessage": " ",
  "licenses.modal.deleteBtnLabel": " ",
  "licenses.modal.delete.title": " ",
  "licenses.modal.delete.description.1": " ",
  "licenses.modal.delete.description.2": " ",
  "licenses.tooltip.changeLicense": " ",
  "licenses.tooltip.deleteLicense": " ",
  "commonTemplate.filter.categoryTitle": " ",
  "commonTemplate.filter.languageTitle": " ",
  "commonTemplate.modalTitle": " ",
  "commonTemplate.searchPlaceholder": " ",
  "category.transportAndLogistics": " ",
  "category.general": " ",
  "category.construction": " ",
  "category.emergencyServices": " ",
  "category.foodAndHospitality": " ",
  "category.healthcare": " ",
  "category.manufacturing": " ",
  "category.safety": " ",
};
