import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { newFindingTypeFormikConstants } from "../../../../initialValues/newFindingTypeInitialValues";

const ExaminatorsField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Checkbox
      label={t("findingTypes.examinators")}
      checked={formik?.values?.[newFindingTypeFormikConstants?.examinators]}
      disabled={props?.disabled}
      onChange={(value) =>
        formik?.setFieldValue?.(
          newFindingTypeFormikConstants.examinators,
          value
        )
      }
    />
  );
};

ExaminatorsField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ExaminatorsField;
