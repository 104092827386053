import styled, { css } from "styled-components";
import { Box, TextField, Typography } from "@mui/material";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import CalendarIcon from "../../Icon/Icons/CalendarIcon";
import Icon from "../../Icon/Icon";

export const TextInputFieldContainer = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  margin: 32px 0 8px 0;
  padding-left: 0;
  position: relative;
`;

export const InputLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.labelTextColor};
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *" !important;
        color: ${selectedTheme.colors.errorColor} !important;
      }
    `};
`;

export const InputFieldContainer = styled(Box)`
  display: flex;
  width: 100%;
  position: relative;
`;
export const InputRightElement = styled(Icon)`
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  width: 36px;
  min-width: 36px;
  height: 100%;
  position: absolute;
  top: 14px;
  /* right: 0; */
  /* left: 94%; */
  left: ${(props) => (props?.isSmall ? "30%" : props?.fullWidth ? "93%" : "90%")};
  /* left: ${(props) => (props?.fullWidth && !props?.isSmall ? "93%" : "90%")}; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  & svg {
    width: 18px;
    height: 18px;
  }
`;

export const InputField = styled(TextField)`
  & input {
    width: ${(props) =>
      props?.type === "checkbox"
        ? "fit-content"
        : props?.isSmall
        ? "120px"
        : "100%"};
    height: 24px;
    padding: 10px 14px;
    border: 1px solid
      ${(props) =>
        props?.$isErrorShown
          ? selectedTheme.colors.errorInputBorderColor
          : selectedTheme.colors.inputBorderColor};
    border-radius: 8px;
    font-family: ${selectedTheme.fonts.mainText};
    font-size: 16px;
    font-feature-settings: "salt" on;
    box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
    background-color: ${(props) =>
      props?.disabled && selectedTheme.colors.disabledFieldBackground};
    -webkit-text-fill-color: ${(props) =>
      props?.disabled &&
      `${selectedTheme.colors.disabledFieldText} !important`};
  }
  & input::placeholder {
    font-family: ${selectedTheme.fonts.mainText};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: 1 !important;
    color: ${selectedTheme.colors.placeholderColor} !important;
  }

  @media (max-width:600px){
    width:90%;
  }
`;
export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
  text-align: ${(props) => props?.$rightAlign && "right"};
  margin-top: 3px;
`;
export const CalendarAdornment = styled(CalendarIcon)`
  position: absolute;
  top: 13px;
  right: 12px;
  z-index: 2;
`;
