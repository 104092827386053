import { Box } from "@mui/material";
import styled from "styled-components";

export const FindingScanningSectionChooserContainer = styled(Box)`
  padding: ${(props) => (props?.$isMobile ? 0 : "12px")};
  overflow: auto;
  max-height: 100%;
  width: 100%;
  overflow-x: hidden; 
`;

export const Div = styled(Box)`
  height: 1px;
`;
