import i18n from "../i18n";

export const newFindingTypeFormikConstants = {
  name: "name",
  caption: "caption",
  subcaption: "subcaption",
  license: "license",
  authorization: "authorization",
  companyLicense: "companyLicense",
  standards: "standards",
  instruments: "instruments",
  instrumentsCalibration: "instrumentsCalibration",
  summary: "summary",
  schema: "schema",
  implementation: "implementation",
  attendees: "attendees",
  examinators: "examinators",
};

export const newFindingTypeInitialValues = (values) => ({
  [newFindingTypeFormikConstants.name]: values?.name || "",
  [newFindingTypeFormikConstants.caption]:
    values?.caption || i18n.t("findings.typeOfData"),
  [newFindingTypeFormikConstants.subcaption]: values?.subcaption || "",
  [newFindingTypeFormikConstants.license]: values?.licenseUrl || null,
  [newFindingTypeFormikConstants.authorization]:
    values?.enableAuthorizedOrganizations == null
      ? true
      : values?.enableAuthorizedOrganizations,
  [newFindingTypeFormikConstants.companyLicense]:
    values?.enableCompanyLicense == null ? true : values?.enableCompanyLicense,
  [newFindingTypeFormikConstants.standards]:
    values?.enableStandards == null ? true : values?.enableStandards,
  [newFindingTypeFormikConstants.instruments]:
    values?.enableInstruments == null ? true : values?.enableInstruments,
  [newFindingTypeFormikConstants.instrumentsCalibration]:
    values?.enableInstrumentsCalibration == null
      ? true
      : values?.enableInstrumentsCalibration,
  [newFindingTypeFormikConstants.summary]:
    values?.enableSummary == null ? true : values?.enableSummary,
  [newFindingTypeFormikConstants.schema]:
    values?.enableSchemas == null ? true : values?.enableSchemas,
  [newFindingTypeFormikConstants.implementation]:
    values?.enableFindingImplementation == null
      ? true
      : values?.enableFindingImplementation,
  [newFindingTypeFormikConstants.attendees]:
    values?.enableAttendees == null ? true : values?.enableAttendees,
  [newFindingTypeFormikConstants.examinators]:
    values?.enableExaminators == null ? true : values?.enableExaminators,
});
