import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import useAuth from "../../../hooks/useAuth";
import { newFindingTypeInitialValues } from "../../../initialValues/newFindingTypeInitialValues";
import {
  fetchCreateFindingType,
  fetchDeleteFindingType,
  fetchEditFindingType,
} from "../../../store/actions/findingTypes/findingTypesActions";
import {
  closeLoadingModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import newFindingTypeValidationSchema from "../../../validations/newFindingTypeValidationSchema";
import PageTitle from "../../PageTitle/PageTitle";
import {
  AddFindingTypeContentContainer,
  SectionsContainer,
} from "./AddFindingTypeContent.styled";
import AuthorizationField from "./AuthorizationField/AuthorizationField";
import CaptionField from "./CaptionField/CaptionField";
import LicenseField from "./LicenseField/LicenseField";
import NameField from "./NameField/NameField";
import Section from "./Section";
import SubcaptionField from "./SubcaptionField/SubcaptionField";
import CompanyLicenseField from "./CompanyLicenseField/CompanyLicenseField";
import StandardsField from "./StandardsField/StandardsField";
import InstrumentsField from "./InstrumentsField/InstrumentsField";
import InstrumentsCalibrationField from "./InstrumentsCalibrationField/InstrumentsCalibrationField";
import SummaryField from "./SummaryField/SummaryField";
import SchemaField from "./SchemaField/SchemaField";
import AttendeesField from "./AttendeesField/AttendeesField";
import ExaminatorsField from "./ExaminatorsField/ExaminatorsField";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import { EDIT_FINDING_TYPE_PAGE } from "../../../constants/pages";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import useIsMobile from "../../../hooks/useIsMobile";
import ActionButtons from "../../PageTitle/ActionButtons/ActionButtons";
import { useHistory } from "react-router-dom";

const AddFindingTypeContent = (props) => {
  const { isManager } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasRole } = useAuth();
  const { isMobile } = useIsMobile();
  const history = useHistory();

  const readOnlyModal = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    let requestObject = {
      name: values?.name?.replace?.(/  +/g, " ")?.trim?.(),
      caption: values?.caption?.replace?.(/  +/g, " ")?.trim?.(),
      subcaption: values?.subcaption?.replace?.(/  +/g, " ")?.trim?.(),
      licenseUrl: values?.license || null,
      enableAuthorizedOrganizations: values?.authorization,
      enableCompanyLicense: values?.companyLicense,
      enableStandards: values?.standards,
      enableInstruments: values?.instruments,
      enableInstrumentsCalibration: values?.instrumentsCalibration,
      enableSummary: values?.summary,
      enableSchemas: values?.schema,
      enableFindingImplementation: true,
      enableAttendees: values?.attendees,
      enableExaminators: values?.examinators,
    };
    if (props?.isEditing)
      dispatch(
        fetchEditFindingType({
          id: props?.prefilledData?.id,
          requestObject: {
            ...requestObject,
            id: props?.prefilledData?.id,
          },
          handleApiResponseSuccess: (payload) =>
            handleApiResponseSuccess({
              ...payload,
              inputValue: values?.name,
              isDeleted: false,
            }),
          handleApiResponseError,
        })
      );
    else
      dispatch(
        fetchCreateFindingType({
          requestObject,
          handleApiResponseSuccess: (payload) =>
            handleApiResponseSuccess({
              id: payload,
              inputValue: values?.name,
              isDeleted: false,
            }),
          handleApiResponseError: handleApiResponseError,
        })
      );
  };
  const initialValues = useMemo(
    () => newFindingTypeInitialValues(props?.prefilledData),
    [props?.prefilledData, t]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: newFindingTypeValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleDelete = () => {
    dispatch(
      toggleSetDeleteModal({
        title: t("findingTypes.modal.delete.title"),
        description: {
          first: t("findingTypes.modal.delete.description.1"),
          second: t("findingTypes.modal.delete.description.2"),
        },
        onSubmit: handleDeleteFindingType,
        rank: 2,
      })
    );
  };
  const handleApiResponseSuccess = (payload) => {
    dispatch(closeLoadingModal());
    const titleI18Key = props?.isEditing
      ? "toast.success.editedTitle"
      : "toast.success.createdTitle";
    const descriptionI18Key = props?.isEditing
      ? "toast.success.editedDescription"
      : "toast.success.createdDescription";
    makeToastMessage(
      {
        title: t(titleI18Key, {
          typeOfData: t("findingTypes.typeOfData"),
        }),
        description: t(descriptionI18Key, {
          name: payload?.name,
          typeOfData: t("findingTypes.typeOfData").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    if (props?.isEditing) return;
    history.replace(
      replaceInRoute(EDIT_FINDING_TYPE_PAGE, {
        findingTypeId: payload?.id,
      })
    );
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleDeleteFindingType = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchDeleteFindingType({
        id: props?.id,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccess({
            inputValue: formik?.values?.name,
            isDeleted: true,
          }),
        handleApiResponseError,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  const isSavingDisabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  return (
    <AddFindingTypeContentContainer rank={props?.rank}>
      <PageTitle
        title={t(props?.isEditing ? props?.prefilledData?.name : props?.title)}
        isEditing={props?.isEditing && isManager}
        isChanged={!isSavingEnabled}
        showDelete={props?.isEditing}
        hideActionButtons={!isManager}
        onDeleteClick={handleDelete}
        disableSaveButton={isSavingEnabled}
        onSubmitClick={formik?.handleSubmit}
      />
      <SectionsContainer>
        <Section title="common.basic">
          <NameField formik={formik} disabled={readOnlyModal} />
          <CaptionField formik={formik} disabled={readOnlyModal} />
          <SubcaptionField formik={formik} disabled={readOnlyModal} />
          <LicenseField formik={formik} disabled={readOnlyModal} />
        </Section>
        <Section title="print.preferences">
          <AuthorizationField formik={formik} disabled={readOnlyModal} />
          <CompanyLicenseField formik={formik} disabled={readOnlyModal} />
          <StandardsField formik={formik} disabled={readOnlyModal} />
          <InstrumentsField formik={formik} disabled={readOnlyModal} />
          <InstrumentsCalibrationField
            formik={formik}
            disabled={readOnlyModal}
          />
          <SummaryField formik={formik} disabled={readOnlyModal} />
          <SchemaField formik={formik} disabled={readOnlyModal} />
          {/* <ImplementationField formik={formik} disabled={readOnlyModal} /> */}
          <AttendeesField formik={formik} disabled={readOnlyModal} />
          <ExaminatorsField formik={formik} disabled={readOnlyModal} />
        </Section>
      </SectionsContainer>
      {isMobile && (
        <>
          <ActionButtons
            disabledSaveButton={isSavingDisabled}
            handleClickSubmitButton={formik?.handleSubmit}
          />
          {}
        </>
      )}
    </AddFindingTypeContentContainer>
  );
};

AddFindingTypeContent.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  deleteProps: PropTypes.object,
  isEditing: PropTypes.bool,
  selectedTypes: PropTypes.any,
  tableRef: PropTypes.any,
  id: PropTypes.number,
  refetchTable: PropTypes.func,
  prefilledData: PropTypes.object,
};
AddFindingTypeContent.defaultProps = {
  title: "findingTypes.modal.newFindingType",
  onSubmit: () => {},
};

export default AddFindingTypeContent;
